<template>
  <div id="rounds-bg" style="min-height: 100vh">
    <div class="round-loading" v-if="loading">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <div id="rounds-content" v-else>
      <div class="round-details-header">
        <a class="round-details-header-back-page" @click="() => handleBack()">
          <font-awesome-icon
            icon="fa-solid fa-arrow-left"
            class="round-details-header-back-page-icon"
          />
          <span class="round-details-header-back-page-text">{{
            $t("rounds_title")
          }}</span>
        </a>
        <div class="round-details-header-flex">
          <div>
            <h1 class="round-details-header-title">
              {{ `${$t("single_round")}  #${roundData.id}` }}
            </h1>
            <p class="round-details-header-sub-title">
              {{ $t("information_about_round_sub_title") }}
            </p>
          </div>
          <div class="round-details-header-buttons">
            <div>
              <NewDxaButton
                :outline="true"
                :title="$t('cancel_round')"
                :disabled="buttonCancelRoundDisabled || isManagerUser"
                @btnFunction="cancelRoundDialog = true"
              />
            </div>
            <div>
              <NewDxaButton
                @btnFunction="closeOrOpenRoundDialog = true"
                :title="$t(closeOrOpenTextButton)"
                :disabled="closeOrOpenRoundButtonDisabled || isManagerUser"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- detalhes das alocações feitas / logo da empresa -->
      <div class="round-allocations-card">
        <div>
          <div class="round-details-allocations-header">
            <div class="rounds-details-allocations-company-logo">
              <img
                :src="gs.get_photo_path(roundData.companyLogo)"
                alt="Company Logo"
              />
            </div>
            <div>
              <h3 class="round-details-allocations-header-round-id">
                {{ `${$t("single_round")}  #${roundData.id}` }}
              </h3>
              <h2 class="round-details-allocations-header-company-name">
                {{ roundData.companyName }}
              </h2>
              <p
                class="round-details-allocations-header-status"
                :style="`color:${selectOpportunityTextColor}`"
              >
                <font-awesome-icon
                  :color="statusCircleIconColor"
                  icon="fa-solid fa-circle"
                  class="round-details-allocations-header-circle-icon"
                />
                {{ $t(selectOpportunityText) }}
              </p>
            </div>
          </div>
          <div class="round-details-allocations-header-details">
            <div>
              <span>{{
                `${roundAllocationData.totalAllocations} ${$t("allocations")}`
              }}</span>
              <a @click="seeRoundAllocations(roundData.id)">{{
                $t("see_allocations")
              }}</a>
            </div>
            <div class="no-gap">
              <span class="space">|</span>
              <span class="total-accesses-label">{{
                `${roundData.totalAccesses ? roundData.totalAccesses : 0} ${$t(
                  "accesses"
                )}`
              }}</span>
              <a
                v-if="!isManagerUser"
                @click="seeRoundAccesses(roundData.id)"
                >{{ $t("see_accesses") }}</a
              >
            </div>
          </div>
          <!-- card com os valores -->
          <div
            class="round-allocations-card-info-container"
            v-if="roundAllocationData"
          >
            <div class="round-allocations-card-info">
              <span class="round-allocations-card-title">
                {{ $t("total_allocations") }}
              </span>
              <span
                v-for="(invested, i) in roundAllocationData.totalInvested"
                :key="i"
                class="round-allocations-card-value"
                >{{
                  invested.value
                    ? formatCurrency(invested.currency, invested.value)
                    : "-"
                }}</span
              >
            </div>
            <div class="round-allocations-card-info">
              <span class="round-allocations-card-title">
                {{ $t("ongoing_allocations") }}
              </span>
              <span
                v-for="(pending, i) in roundAllocationData.totalRequestPending"
                :key="i"
                class="round-allocations-card-value"
                >{{
                  pending.value
                    ? formatCurrency(pending.currency, pending.value)
                    : "-"
                }}</span
              >
            </div>
            <div class="round-allocations-card-info">
              <span class="round-allocations-card-title">
                {{ $t("confirmed_transfers") }}
              </span>
              <span
                v-for="(
                  confirmed, i
                ) in roundAllocationData.totalRequestConfirmed"
                :key="i"
                class="round-allocations-card-value"
                >{{
                  confirmed.value
                    ? formatCurrency(confirmed.currency, confirmed.value)
                    : "-"
                }}</span
              >
            </div>
          </div>
          <!-- setores -->
          <div class="round-allocations-sectors">
            <span class="round-allocations-sector-name">
              {{ roundAllocationData.company.sector }}
            </span>
          </div>
          <!-- ultima atualização -->
          <span class="round-allocations-last-update">{{
            $t("last_update_in", { date: lastUpdate })
          }}</span>
        </div>
      </div>

      <!-- inicia a parte de detalhes da rodada -->
      <div class="round-details-content">
        <div class="round-details-content-header">
          <div>
            <h1 class="round-details-content-header-title">
              {{ $t("round_details_title") }}
            </h1>
            <p class="round-details-content-header-sub-title">
              {{
                $t("round_details_actions", {
                  status: roundDetailsStatus,
                  date: formatData(roundData.updatedAt),
                  user_name: roundData.updatedBy,
                })
              }}
            </p>
          </div>
          <div class="round-details-content-button-edit">
            <NewDxaButton
              data-test="Round:RoundDetails:ButtonGoEditRound"
              @btnFunction="editRound"
              :title="$t('edit_round_information')"
              :disabled="buttonEditRoundDisabled || isManagerUser"
            />
          </div>
        </div>
        <div class="round-details-content-bg">
          <!-- dados gerais de uma rodada -->
          <ul class="round-details-content-list">
            <li class="round-details-content-list-item">
              <h2 class="round-details-content-items-label">
                {{ $tc("company", 1) }}
              </h2>
              <div class="round-details-company-flex">
                <div class="round-details-content-items-value">
                  {{ roundData.companyName }}
                </div>
                <a
                  v-if="!isManagerUser"
                  @click="redirectToCompanyDetails(roundData.companyId)"
                  class="round-details-see-company-details"
                >
                  {{ $t("see_company_details") }}
                </a>
              </div>
            </li>
            <li class="round-details-content-list-item">
              <h2 class="round-details-content-items-label">
                {{ $t("manager_company") }}
              </h2>
              <div class="round-details-company-flex">
                <div class="round-details-content-items-value">
                  <p
                    class="round-details-content-items-value"
                    :class="{
                      'round-details-content-items-value-not-found':
                        !roundData.managerPartnerName,
                    }"
                  >
                    {{
                      roundData.managerPartnerName
                        ? roundData.managerPartnerName
                        : $t("not_defined")
                    }}
                  </p>
                </div>
                <a
                  v-if="roundData.managerPartnerId && !isManagerUser"
                  @click="handleRedirectManagerCompany"
                  class="round-details-see-company-details"
                >
                  {{ $t("see_manager_company_details") }}
                </a>
              </div>
            </li>
            <li class="round-details-content-list-item">
              <h2 class="round-details-content-items-label">
                {{ $t("round_investment_target") }}
              </h2>
              <p
                class="round-details-content-items-value"
                :class="{
                  'round-details-content-items-value-not-found':
                    !roundData.investmentTarget,
                }"
              >
                {{
                  roundData.investmentTarget
                    ? gs.format_to_currency(roundData.investmentTarget)
                    : $t("not_defined")
                }}
              </p>
            </li>
            <li class="round-details-content-list-item">
              <h2 class="round-details-content-items-label">
                {{ $t("status") }}
              </h2>
              <p class="round-details-content-items-value">
                {{ getStatusText(roundData.roundStatus) }}
              </p>
            </li>
            <li class="round-details-content-list-item">
              <h2 class="round-details-content-items-label">
                {{ $t("startDate") }}
              </h2>
              <p
                class="round-details-content-items-value"
                :class="{
                  'round-details-content-items-value-not-found': !formatData(
                    roundData.captureStartDate
                  ),
                }"
              >
                {{
                  checkValueTextIsValid(formatData(roundData.captureStartDate))
                }}
              </p>
            </li>
            <li class="round-details-content-list-item">
              <h2 class="round-details-content-items-label">
                {{ $t("endDate") }}
              </h2>
              <p
                class="round-details-content-items-value"
                :class="{
                  'round-details-content-items-value-not-found': !formatData(
                    roundData.captureFinishDate
                  ),
                }"
              >
                {{
                  checkValueTextIsValid(formatData(roundData.captureFinishDate))
                }}
              </p>
            </li>
            <li class="round-details-content-list-item">
              <h2 class="round-details-content-items-label">
                {{ $t("briefing") }}
              </h2>
              <a
                @click="() => handleGoBriefing()"
                class="round-details-content-link"
                >{{ $t("see_briefing_information") }}</a
              >
            </li>
            <li class="round-details-content-list-item">
              <h2 class="round-details-content-items-label">
                {{ $t("round_permission_opportinity") }}
              </h2>
              <div
                class="round-details-content-items-value"
                :class="{
                  'round-details-content-items-value-not-found':
                    !roundData.typeOpportunity,
                }"
              >
                <div
                  v-if="roundData.typeOpportunity"
                  class="round-details-content-type-opportunity-box"
                >
                  <span>{{ getTypeRound(roundData.typeOpportunity) }} </span>
                  <span
                    v-if="
                      roundData.typeOpportunity ===
                      roundTypeOpportunity.ExclusiveOpportunity
                    "
                  >
                    <a
                      v-if="!isManagerUser"
                      @click="
                        $router.push(`/round/permissions/${roundData.id}`)
                      "
                      class="round-details-content-link"
                      >{{ $t("see_permissions") }}
                    </a>
                  </span>
                </div>
                <span v-else>{{ $t("not_defined") }}</span>
              </div>
            </li>
          </ul>
          <!-- dados de commitment -->
          <div class="round-details-content-commitment">
            <!-- commitment documentos para commitment -->
            <h2 class="round-details-content-commitment-title">
              {{ $t("commitment_documents") }}
            </h2>
            <ul class="round-details-content-list">
              <li class="round-details-content-list-item">
                <h4 class="round-details-content-list-item-term-language">
                  {{ $t("term_title_language_pt") }}
                </h4>
                <h3 class="round-details-content-items-label">
                  {{ $t("commitment_term_key", { language: "pt" }) }}
                </h3>
                <p
                  class="round-details-content-link"
                  :class="{
                    'round-details-content-items-value-not-found':
                      JSON.parse(roundData.commitmentTermUrl).pt === '',
                  }"
                >
                  {{
                    checkParseValueTextIsValid(
                      roundData.commitmentTermUrl,
                      "pt"
                    )
                  }}
                </p>
              </li>
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ $t("proxy_key", { language: "pt" }) }}
                </h3>
                <p
                  class="round-details-content-link"
                  :class="{
                    'round-details-content-items-value-not-found':
                      JSON.parse(roundData.investmentProxyUrl).pt === '',
                  }"
                >
                  {{
                    checkParseValueTextIsValid(
                      roundData.investmentProxyUrl,
                      "pt"
                    )
                  }}
                </p>
              </li>
              <!-- termos para estrangeiros -->
              <li class="round-details-content-list-item">
                <h4 class="round-details-content-list-item-term-language">
                  {{ $t("term_title_language_en") }}
                </h4>
                <h3 class="round-details-content-items-label">
                  {{ $t("commitment_term_key", { language: "en" }) }}
                </h3>
                <p
                  class="round-details-content-link"
                  :class="{
                    'round-details-content-items-value-not-found':
                      JSON.parse(roundData.commitmentTermUrl).en === '',
                  }"
                >
                  {{
                    checkParseValueTextIsValid(
                      roundData.commitmentTermUrl,
                      "en"
                    )
                  }}
                </p>
              </li>
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ $t("proxy_key", { language: "en" }) }}
                </h3>
                <p
                  class="round-details-content-link"
                  :class="{
                    'round-details-content-items-value-not-found':
                      JSON.parse(roundData.investmentProxyUrl).en === '',
                  }"
                >
                  {{
                    checkParseValueTextIsValid(
                      roundData.investmentProxyUrl,
                      "en"
                    )
                  }}
                </p>
              </li>
            </ul>

            <!-- commitment condições especiais por parceiro -->
            <h2
              class="round-details-content-commitment-title round-details-content-partner-conditions-title"
            >
              {{ $t("partner_specials_conditions") }}
            </h2>
            <!-- verificando se existe alguma condição especial por parceiro se existir o loop ocorre pegando as informações,
            caso não exista vai ser usado uma lista fixa, que simplesmente foi criada para atender os estilos definido no protótipo
             -->
            <div
              v-if="roundData.partnerConditionInvestmentRounds.length"
              class="round-details-content-commitment-partner"
            >
              <ul
                class="round-details-content-list"
                v-for="(
                  partner, i
                ) in roundData.partnerConditionInvestmentRounds"
                :key="i"
              >
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $tc("partner", 1) }}
                  </h3>
                  <p class="round-details-content-items-value">
                    {{ partner.partnerB2bName }}
                  </p>
                </li>
                <!-- termos do parceiro - brasileiro -->
                <li>
                  <h4 class="round-details-content-list-item-term-language">
                    {{ $t("term_title_language_pt") }}
                  </h4>
                </li>
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $t("commitment_term_key", { language: "pt" }) }}
                  </h3>
                  <p class="round-details-content-link">
                    {{ JSON.parse(partner.partnerCommitmentTermUrl).pt }}
                  </p>
                </li>
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $t("proxy_key", { language: "pt" }) }}
                  </h3>
                  <p class="round-details-content-link">
                    {{ JSON.parse(partner.partnerInvestmentProxyUrl).pt }}
                  </p>
                </li>
                <!-- termos do parceiro - estrangeiros -->
                <li>
                  <h4 class="round-details-content-list-item-term-language">
                    {{ $t("term_title_language_en") }}
                  </h4>
                </li>
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $t("commitment_term_key", { language: "en" }) }}
                  </h3>
                  <p class="round-details-content-link">
                    {{ JSON.parse(partner.partnerCommitmentTermUrl).en }}
                  </p>
                </li>
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $t("proxy_key", { language: "en" }) }}
                  </h3>
                  <p class="round-details-content-link">
                    {{ JSON.parse(partner.partnerInvestmentProxyUrl).en }}
                  </p>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul class="round-details-content-list">
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $tc("partner", 1) }}
                  </h3>
                  <p
                    class="round-details-content-items-value round-details-content-items-value-not-found"
                  >
                    {{ $t("not_defined") }}
                  </p>
                </li>
                <!-- termos não definidos parceiros brasileiros -->
                <li>
                  <h4 class="round-details-content-list-item-term-language">
                    {{ $t("term_title_language_pt") }}
                  </h4>
                </li>
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $t("commitment_term_key", { language: "pt" }) }}
                  </h3>
                  <p
                    class="round-details-content-items-value round-details-content-items-value-not-found"
                  >
                    {{ $t("not_defined") }}
                  </p>
                </li>
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $t("proxy_key", { language: "pt" }) }}
                  </h3>
                  <p
                    class="round-details-content-items-value round-details-content-items-value-not-found"
                  >
                    {{ $t("not_defined") }}
                  </p>
                </li>
                <!-- termos não definidos parceiros estrangeiros -->
                <li>
                  <h4 class="round-details-content-list-item-term-language">
                    {{ $t("term_title_language_en") }}
                  </h4>
                </li>
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $t("commitment_term_key", { language: "en" }) }}
                  </h3>
                  <p
                    class="round-details-content-items-value round-details-content-items-value-not-found"
                  >
                    {{ $t("not_defined") }}
                  </p>
                </li>
                <li class="round-details-content-list-item">
                  <h3 class="round-details-content-items-label">
                    {{ $t("proxy_key", { language: "en" }) }}
                  </h3>
                  <p
                    class="round-details-content-items-value round-details-content-items-value-not-found"
                  >
                    {{ $t("not_defined") }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <!-- documentos de closing -->
          <div class="round-details-content-closing">
            <h2 class="round-details-content-closing-title">
              {{ $t("closing_documents") }}
            </h2>
            <ul
              class="round-details-content-list"
              v-for="(vehicle, i) in saVehicles(
                roundData.investmentVehicleInvestmentRounds
              )"
              :key="i"
            >
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ `${$t("vehicle_title_single")}` }}
                </h3>
                <div class="round-details-content-closing-vehicle">
                  <p class="round-details-content-items-value">
                    {{
                      `${vehicle.vehicleName} - ${formatVehicleCnpj(
                        vehicle.vehicleCnpj
                      )}`
                    }}
                  </p>
                  <a
                    v-if="!isManagerUser"
                    @click="
                      redirectToVehicleDetails(vehicle.investmentVehicleId)
                    "
                    class="round-details-content-link"
                    >{{ $t("see_vehicle_details") }}</a
                  >
                  <span class="vehicle-nationality">
                    <font-awesome-icon
                      class="icon-vehicle-nationality"
                      icon="fa-solid fa-database"
                    />
                    {{
                      getVehicleNationality(vehicle.vehicleNationality)
                    }}</span
                  >
                </div>
              </li>
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ $t("stock_type") }}
                </h3>
                <p
                  class="round-details-content-items-value"
                  :class="{
                    'round-details-content-items-value-not-found':
                      !vehicle.stockTypeId,
                  }"
                >
                  <span v-if="vehicle.stockTypeId"
                    >{{
                      `${vehicle.stockTypeName} - ${formatCurrency(
                        vehicle.currency,
                        vehicle.stockTypeValue,
                        2,
                        5
                      )}`
                    }}
                  </span>
                  <span v-else>{{ $t("not_defined") }}</span>
                </p>
              </li>
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ $t("round_closing_adhesion_term") }}
                </h3>
                <p class="round-details-content-items-value">
                  {{ $tc("clicksign_key", 2) }}
                  <span
                    :class="{
                      'round-details-content-items-value-not-found':
                        !vehicle.adhesionTermSignatureKey,
                    }"
                    >{{
                      checkValueTextIsValid(vehicle.adhesionTermSignatureKey)
                    }}</span
                  >
                </p>
              </li>
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ $t("newsletter_subscription_2") }}
                </h3>
                <p class="round-details-content-items-value">
                  {{ $tc("clicksign_key", 2) }}
                  <span
                    :class="{
                      'round-details-content-items-value-not-found':
                        !vehicle.newsletterSubscriptionSignatureKey,
                    }"
                    >{{
                      checkValueTextIsValid(
                        vehicle.newsletterSubscriptionSignatureKey
                      )
                    }}</span
                  >
                </p>
              </li>
            </ul>

            <ul
              class="round-details-content-list"
              v-for="(vehicle, i) in llcVehicles(
                roundData.investmentVehicleInvestmentRounds
              )"
              :key="i"
            >
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ `${$t("vehicle_title_single")}` }}
                </h3>
                <div class="round-details-content-closing-vehicle">
                  <p class="round-details-content-items-value">
                    {{ `${vehicle.vehicleName}` }}
                  </p>
                  <a
                    v-if="!isManagerUser"
                    @click="
                      redirectToVehicleDetails(vehicle.investmentVehicleId)
                    "
                    class="round-details-content-link"
                    >{{ $t("see_vehicle_details") }}</a
                  >
                  <span class="vehicle-nationality">
                    <font-awesome-icon
                      class="icon-vehicle-nationality"
                      icon="fa-solid fa-database"
                    />
                    {{
                      getVehicleNationality(vehicle.vehicleNationality)
                    }}</span
                  >
                </div>
              </li>
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ $t("stock_type") }}
                </h3>
                <p
                  class="round-details-content-items-value"
                  :class="{
                    'round-details-content-items-value-not-found':
                      !vehicle.stockTypeId,
                  }"
                >
                  <span v-if="vehicle.stockTypeId"
                    >{{
                      `${vehicle.stockTypeName} - ${formatCurrency(
                        vehicle.currency,
                        vehicle.stockTypeValue,
                        2,
                        5
                      )}`
                    }}
                  </span>
                  <span v-else>{{ $t("not_defined") }}</span>
                </p>
              </li>
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ $t("round_closing_subscription_agreement") }}
                </h3>
                <p class="round-details-content-items-value">
                  {{ $tc("clicksign_key", 2) }}
                  <span
                    :class="{
                      'round-details-content-items-value-not-found':
                        !vehicle.subscriptionAgreementSignatureKey,
                    }"
                    >{{
                      checkValueTextIsValid(
                        vehicle.subscriptionAgreementSignatureKey
                      )
                    }}</span
                  >
                </p>
              </li>
            </ul>
            <ul
              class="round-details-content-list"
              v-for="(vehicle, i) in offVehicles(
                roundData.investmentVehicleInvestmentRounds
              )"
              :key="i"
            >
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ `${$t("vehicle_title_single")}` }}
                </h3>
                <div class="round-details-content-closing-vehicle">
                  <p class="round-details-content-items-value">
                    {{ `${vehicle.vehicleName}` }}
                  </p>
                  <a
                    v-if="!isManagerUser"
                    @click="
                      redirectToVehicleDetails(vehicle.investmentVehicleId)
                    "
                    class="round-details-content-link"
                    >{{ $t("see_vehicle_details") }}</a
                  >
                  <span class="vehicle-nationality">
                    <font-awesome-icon
                      class="icon-vehicle-nationality"
                      icon="fa-solid fa-database"
                    />
                    {{
                      getVehicleNationality(vehicle.vehicleNationality)
                    }}</span
                  >
                </div>
              </li>
              <li class="round-details-content-list-item">
                <h3 class="round-details-content-items-label">
                  {{ $t("stock_type") }}
                </h3>
                <p
                  class="round-details-content-items-value"
                  :class="{
                    'round-details-content-items-value-not-found':
                      !vehicle.stockTypeId,
                  }"
                >
                  <span v-if="vehicle.stockTypeId"
                    >{{
                      `${vehicle.stockTypeName} - ${formatCurrency(
                        vehicle.currency,
                        vehicle.stockTypeValue,
                        2,
                        5
                      )}`
                    }}
                  </span>
                  <span v-else>{{ $t("not_defined") }}</span>
                </p>
              </li>
              <li>
                <h3 class="round-details-content-items-label">
                  {{ $t("no_formalization_documents") }}
                </h3>
              </li>
            </ul>
          </div>

          <div class="round-details-content-button-edit-end-page">
            <NewDxaButton
              data-test="Round:RoundDetails:ButtonGoEditRound"
              @btnFunction="editRound"
              :title="$t('edit_round_information')"
              :disabled="buttonEditRoundDisabled || isManagerUser"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- dialog de fechar/abrir uma rodada -->
    <v-dialog
      max-width="564"
      v-model="closeOrOpenRoundDialog"
      v-if="closeOrOpenRoundDialog"
    >
      <div class="close-or-open-round-dialog-bg">
        <div class="close-or-open-round-dialog-content">
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation"
            class="close-or-open-round-dialog-icon-alert"
          />
          <h1 class="close-or-open-round-dialog-title">
            {{ closeOrOpenRoundDialogTitle }}
          </h1>
          <p class="close-or-open-round-dialog-text">
            {{ closeOrOpenRoundDialogText }}
          </p>
          <div class="close-or-open-round-dialog-buttons">
            <div>
              <NewDxaButton
                @btnFunction="closeOrOpenRoundDialog = false"
                :outline="true"
                :title="$t('cancel')"
              />
            </div>
            <div>
              <NewDxaButton
                :loading="closeOrOpenRoundLoading"
                @btnFunction="closeOrOpenRound"
                :title="closeOrOpenRoundDialogButton"
              />
            </div>
          </div>
        </div>
        <font-awesome-icon
          @click="closeOrOpenRoundDialog = false"
          icon="fa-solid fa-xmark"
          class="close-or-open-round-dialog-icon-close"
        />
      </div>
    </v-dialog>
    <!-- dialog de alert para cancelar uma rodada -->
    <v-dialog
      max-width="464"
      v-model="cancelRoundDialog"
      v-if="cancelRoundDialog"
    >
      <div class="cancel-round-dialog-bg">
        <div class="cancel-round-dialog-content">
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation"
            class="cancel-round-dialog-icon-alert"
          />
          <h1 class="cancel-round-dialog-title">
            {{ $t("cancel_round_dialog_title") }}
          </h1>
          <p
            class="cancel-round-dialog-text"
            v-html="dialogCancelRoundText"
          ></p>
          <div class="cancel-round-dialog-buttons">
            <div>
              <NewDxaButton
                @btnFunction="cancelRoundDialog = false"
                :outline="true"
                :title="$t('close')"
              />
            </div>
            <div>
              <NewDxaButton
                :loading="cancelRoundDialogLoading"
                @btnFunction="cancelRound"
                :title="$t('cancel')"
              />
            </div>
          </div>
        </div>
        <font-awesome-icon
          @click="cancelRoundDialog = false"
          icon="fa-solid fa-xmark"
          class="cancel-round-dialog-icon-close"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import GeneralServices from "@/services/GeneralServices";
import { roundStatusEnums, roundTypeOpportunity } from "./RoundStatusEnums.js";
import { findSelectedStatus } from "./helper/findStatusText.js";
import moment from "moment";
import { formatCnpj } from "@/shared/helpers/cnpjHelper.js";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import {
  vehicleClosingOptionsEnum,
  vehicleNationality,
} from "@/shared/enums/VehicleEnum";
import { mapGetters, mapMutations } from "vuex";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "RoundDetails",
  data() {
    return {
      apiService: new ApiService(),
      gs: new GeneralServices(),
      roundId: null,
      roundData: null,
      formatVehicleCnpj: formatCnpj,
      statusCircleIconColor: null,
      selectOpportunityText: null,
      roundDetailsStatus: null,
      roundTypeOpportunity: roundTypeOpportunity,
      loading: true,
      closeOrOpenRoundDialog: false,
      closeOrOpenRoundLoading: false,
      closeOrOpenTextButton: null,
      closeOrOpenRoundDialogButton: null,
      closeOrOpenRoundDialogTitle: null,
      closeOrOpenRoundDialogText: null,
      closeOrOpenRoundButtonDisabled: true,
      buttonEditRoundDisabled: true,
      buttonCancelRoundDisabled: true,
      cancelRoundDialog: false,
      cancelRoundDialogLoading: false,
      formatCurrency,
      CurrencyEnum,
      roundAllocationData: null,
      lastUpdate: null,
      vehicleClosingOptionsEnum,
      vehicleNationality,
      UserTypeEnum,
    };
  },
  components: {
    NewDxaButton,
  },
  async created() {
    this.roundId = this.$route.params.roundId;
    let userLocal = JSON.parse(localStorage.getItem("user"));

    if (
      userLocal.type === this.UserTypeEnum.ManagerUser ||
      (userLocal.managerPartner && userLocal.managerPartner.id)
    ) {
      this.setIsManagerUser(true);
    } else {
      this.setIsManagerUser(false);
    }

    // colocando todas as atualizações do dom em methods para facilitar a alteração dos dados no dom quado ocorrer as ações
    // dos botões existentes na tela, assim é só chamar uma função de update, para recriar o componente e atualizar todos os estados
    await this.getRoundData();
    await this.getRoundAllocationsData();
    await this.selectOpportunityTextStyles();
    await this.defineContentDialogCloseOrOpenRound();
    await this.defineActiveOrDisabledButtons();
  },

  computed: {
    dialogCancelRoundText() {
      return `
        <span>${this.$t("cancel_round_dialog_sub_title", {
          roundId: this.roundData.id,
          companyName: this.roundData.companyName,
        })}</span>
      `;
    },
    ...mapGetters({ isManagerUser: "get_is_manager_user" }),
  },
  methods: {
    ...mapMutations({
      setIsManagerUser: "SET_IS_MANAGER_USER",
    }),
    handleBack() {
      // se for managerUser vamos garantir que ele sempre volte para a tela de rodadas
      if (this.isManagerUser) {
        this.$router.push("/round");
        return;
      }

      // default
      this.$router.go(-1);
    },
    handleGoBriefing() {
      // se for managerUser ele vai para o briefing de visualização da empresa.
      if (this.isManagerUser) {
        this.$router.push(
          `/companies/${this.roundData.companyId}/briefing/${this.roundData.briefingId}`
        );
        return;
      }

      this.$router.push(`/round/new/briefing/${this.roundData.briefingId}`);
    },
    getVehicleNationality(nationality) {
      const literal = {
        [this.vehicleNationality.NATIONAL]: this.$t("national"),
        [this.vehicleNationality.INTERNATIONAL]: this.$t("international"),
      };

      return literal[nationality];
    },
    saVehicles(vehicles) {
      return vehicles.filter(
        (v) => v.closingType == vehicleClosingOptionsEnum.CLOSING_SA
      );
    },
    llcVehicles(vehicles) {
      return vehicles.filter(
        (v) => v.closingType == vehicleClosingOptionsEnum.CLOSING_LLC
      );
    },
    offVehicles(vehicles) {
      return vehicles.filter(
        (v) => v.closingType == vehicleClosingOptionsEnum.NO_CLOSING
      );
    },
    seeRoundAllocations(id) {
      this.$router.push(`/round/allocations/${id}`);
    },
    seeRoundAccesses(id) {
      this.$router.push(`/round/accesses/${id}`);
    },
    editRound() {
      this.$router.push(`/round/${this.roundId}`);
    },
    async getRoundAllocationsData() {
      this.loading = true;
      const roundId = this.$route.params.roundId;
      await this.apiService
        .getRequest(`investmentround/${roundId}/allocations`)
        .then((result) => {
          this.roundAllocationData = result.content;
          this.lastUpdate = format(
            new Date(result.content.lastUpdate),
            "dd/MM/yyyy",
            {
              locale: ptBR,
            }
          );
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.loading = false;
    },
    async getRoundData() {
      this.loading = true;
      await this.apiService
        .getRequest(`investmentround/${this.roundId}`)
        .then((result) => {
          this.roundData = result.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.loading = false;
    },
    getStatusText(status) {
      return this.$t(findSelectedStatus(status));
    },
    checkValueTextIsValid(value) {
      if (value) {
        return value;
      } else {
        return this.$t("not_defined");
      }
    },
    checkParseValueTextIsValid(object, prop) {
      let parsedValue = JSON.parse(object);

      if (parsedValue[prop] === "") {
        return this.$t("not_defined");
      } else {
        return parsedValue[prop];
      }
    },
    formatData(value) {
      if (value == "0001-01-01T00:00:00") return null;
      return moment(value).format("DD/MM/YYYY ");
    },
    getTypeRound(typeOpportunity) {
      if (typeOpportunity === roundTypeOpportunity.AvailableOpportunity) {
        return this.$t("opportunity_available");
      } else if (
        typeOpportunity === roundTypeOpportunity.ExclusiveOpportunity
      ) {
        return this.$t("opportunity_exclusive");
      }
    },
    redirectToVehicleDetails(vehicleId) {
      this.$router.push({
        path: `/vehicle/edit/${vehicleId}`,
      });
    },
    handleRedirectManagerCompany() {
      if (this.isManagerUser) return;

      this.$router.push(
        `/managerPartner?id=${this.roundData.managerPartnerId}`
      );
    },
    redirectToCompanyDetails(companyId) {
      if (this.isManagerUser) return;

      this.$router.push({
        path: `/companies/${companyId}`,
        params: { company_id: companyId },
        query: { tab: 1 },
      });
    },
    selectOpportunityTextStyles() {
      this.loading = true;
      const documentStyle = window.getComputedStyle(document.documentElement);
      const documentDarkColor = documentStyle.getPropertyValue("--dark");

      switch (this.roundData.roundStatus) {
        case roundStatusEnums.AWAITING_PUBLISH:
          this.statusCircleIconColor = "#DADADA";
          this.selectOpportunityText = "round_status_await_publish";
          this.roundDetailsStatus = this.$t("round_status_created");
          break;
        case roundStatusEnums.PUBLISHED:
          this.statusCircleIconColor = "#01D181";
          this.selectOpportunityText = "opportunity_open";
          this.roundDetailsStatus = this.$t("round_status_published");
          break;
        case roundStatusEnums.CLOSED:
          this.statusCircleIconColor = documentDarkColor;
          this.selectOpportunityText = "opportunity_closed";
          this.selectOpportunityTextColor = documentDarkColor;
          this.roundDetailsStatus = this.$t("round_status_closed");
          break;
        case roundStatusEnums.CANCELED:
          this.statusCircleIconColor = "#FF0000";
          this.selectOpportunityText = "opportunity_canceled";
          this.selectOpportunityTextColor = "#FF0000";
          this.roundDetailsStatus = this.$t("round_status_canceled");
          break;
        default:
          this.selectOpportunityTextColor = "#535353";
      }
      this.loading = false;
    },
    defineContentDialogCloseOrOpenRound() {
      if (this.roundData.roundStatus === roundStatusEnums.CLOSED) {
        this.closeOrOpenRoundDialogTitle = this.$t("open_round_dialog_title");
        this.closeOrOpenRoundDialogText = this.$t(
          "open_round_dialog_sub_title",
          {
            roundId: this.roundData.id,
            companyName: this.roundData.companyName,
          }
        );
        this.closeOrOpenRoundDialogButton = this.$t("open");
      } else {
        this.closeOrOpenRoundDialogTitle = this.$t("close_round_dialog_title");
        this.closeOrOpenRoundDialogText = this.$t(
          "close_round_dialog_sub_title",
          {
            roundId: this.roundData.id,
            companyName: this.roundData.companyName,
          }
        );
        this.closeOrOpenRoundDialogButton = this.$t("close");
      }
    },
    async closeOrOpenRound() {
      this.closeOrOpenRoundLoading = true;

      await this.apiService
        .putRequest(`investmentround/close-reopen/${this.roundData.id}`)
        .then((result) => {
          this.closeOrOpenRoundDialog = false;
          this.$toast.success(this.$t("saved"));
          this.updateRound();
        })
        .catch((err) => {
          this.closeOrOpenRoundDialog = false;
          this.$toast.error(this.$t("error_occoured"));
        });

      this.closeOrOpenRoundLoading = false;
    },
    defineActiveOrDisabledButtons() {
      this.loading = true;
      switch (this.roundData.roundStatus) {
        case roundStatusEnums.AWAITING_PUBLISH:
          this.closeOrOpenRoundButtonDisabled = true;
          this.buttonCancelRoundDisabled = false;
          this.buttonEditRoundDisabled = false;
          this.closeOrOpenTextButton = "close_round";
          break;
        case roundStatusEnums.PUBLISHED:
          this.closeOrOpenRoundButtonDisabled = false;
          this.buttonCancelRoundDisabled = false;
          this.buttonEditRoundDisabled = false;
          this.closeOrOpenTextButton = "close_round";
          break;
        case roundStatusEnums.CLOSED:
          this.buttonEditRoundDisabled = true;
          this.buttonCancelRoundDisabled = true;
          this.closeOrOpenRoundButtonDisabled = false;
          this.closeOrOpenTextButton = "open_round";
          break;
        case roundStatusEnums.CANCELED:
          this.buttonEditRoundDisabled = true;
          this.buttonCancelRoundDisabled = true;
          this.closeOrOpenRoundButtonDisabled = true;
          this.closeOrOpenTextButton = "close_round";
          break;
        default:
          this.closeOrOpenTextButton = "close_round";
          this.closeOrOpenRoundButtonDisabled = false;
          this.buttonEditRoundDisabled = false;
          this.buttonCancelRoundDisabled = false;
      }
      this.loading = false;
    },
    async cancelRound() {
      this.cancelRoundDialogLoading = true;

      await this.apiService
        .putRequest(`investmentround/cancel?id=${this.roundData.id}`)
        .then((result) => {
          this.cancelRoundDialog = false;
          this.$toast.success(this.$t("saved"));
          this.updateRound();
        })
        .catch((err) => {
          this.cancelRoundDialog = false;
          this.$toast.error(this.$t("error_occoured"));
        });

      this.cancelRoundDialogLoading = false;
    },
    // é preciso criar uma função update para atualizar toda fez que ocorrer uma ação na tela para ele recriar o componente mesma funcionalidade do created
    async updateRound() {
      await this.getRoundData();
      await this.selectOpportunityTextStyles();
      await this.defineContentDialogCloseOrOpenRound();
      await this.defineActiveOrDisabledButtons();
    },
  },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

p,
h2,
h3 {
  padding: 0px;
  margin: 0px;
}

#rounds-bg {
  position: absolute;
  top: -75px;
  width: 100%;
  min-height: 100vh !important;
  background-color: var(--white);
}

#rounds-content {
  /* 75px precisa ser fixo para das a distancia do AppBar */
  margin-top: calc(75px + 20px);
  width: 100%;
  padding: 0 60px;
  background-color: var(--white);
  padding-bottom: 40px;
}

.round-details-header {
  margin-bottom: 48px;
}

.round-details-header-back-page {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}

.round-details-header-back-page-icon {
  width: 22px;
  height: 22px;
  color: var(--primary);
}

.round-details-header-back-page-text {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.round-details-header-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);

  margin-bottom: 8px;
}

.round-details-header-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-details-header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.round-details-header-buttons {
  display: flex;
  align-items: center;
  gap: 60px;
}

.round-details-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.round-details-content-header-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
}

.round-details-content-header-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-details-content {
  margin-top: 48px;
}

.round-details-content-button-edit {
  min-width: 360px;
}

.round-details-content-header {
  margin-bottom: 36px;
}

.round-details-content-items-label {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  max-width: max-content;
}

.round-details-content-items-value {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
  max-width: max-content;
}

.round-details-content-list-item {
  border-bottom: 2px solid #e9e9e9;
  padding-bottom: 16px;

  display: grid;
  grid-template-columns: minmax(auto, 200px) auto;
  align-items: center;
  gap: 16px;
}

.round-details-content-list li + li {
  margin-top: 35px;
}

.round-details-content-bg {
  background: var(--white);
  border-radius: 12px;
  padding: 35px 32px;

  display: grid;
}

.round-details-company-flex {
  display: flex;
  align-items: center;
  gap: 60px;
}

.round-details-see-company-details {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.round-details-content-link {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.round-details-content-commitment {
  margin-top: 50px;
}

.round-details-content-commitment-title,
.round-details-content-closing-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 25px;
}

.round-details-content-closing-title {
  margin-top: 30px;
}

.round-details-content-partner-conditions-title {
  margin-top: 30px;
}

.round-details-content-closing-vehicle {
  display: flex;
  align-items: center;
  gap: 40px;
}

.round-details-content-closing > ul + ul,
.round-details-content-commitment-partner > ul + ul {
  margin-top: 32px;
}

.round-details-content-button-edit-end-page {
  width: 324px;
  margin-top: 30px;
  justify-self: end;
}

.rounds-details-allocations-company-logo,
.rounds-details-allocations-company-logo > img {
  width: 96px;
  height: 96px;
}

.round-details-allocations-header {
  display: flex;
  gap: 12px;
}

.round-details-allocations-header-round-id {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 4px;
}

.round-details-allocations-header-company-name {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
}

.round-details-allocations-header-status {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;

  display: flex;
  align-items: center;
  gap: 8px;
}

.round-details-allocations-header-circle-icon {
  width: 24px;
  height: 24px;
}

.round-details-allocations-header-details {
  display: flex;
  align-items: center;
}

.round-details-allocations-header-details > div {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  margin-top: 30px;
}

.round-details-allocations-header-details > div.no-gap {
  gap: 0px;
}

.round-details-allocations-header-details
  > div.no-gap
  span.total-accesses-label {
  margin-right: 12px;
}

.round-details-allocations-header-details span {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.15px;
  color: var(--dark);
  text-transform: lowercase;
}

.round-details-allocations-header-details a {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.15px;
  text-decoration: underline;
  color: var(--primary);
}

.expansion-panel-header-icon {
  position: absolute;
  top: 30px;
  right: 28px;
}

.round-loading {
  display: grid;
  place-items: center;
  margin-top: 120px;
}

/* importante ficar no final */
.round-details-content-items-value-not-found {
  color: #aaaaaa;
  font-weight: 400;
}

.close-or-open-round-dialog-bg,
.cancel-round-dialog-bg {
  background: var(--white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: relative;
}

.close-or-open-round-dialog-content,
.cancel-round-dialog-content {
  display: grid;
  place-items: center;
  padding: 20px;
  padding-top: 48px;
}

.close-or-open-round-dialog-title,
.cancel-round-dialog-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  text-align: center;
  margin-bottom: 10px;
}

.close-or-open-round-dialog-text,
.cancel-round-dialog-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 28px;
}

.close-or-open-round-dialog-buttons,
.cancel-round-dialog-buttons {
  display: flex;
  align-items: center;
  gap: 40px;
}

.close-or-open-round-dialog-buttons > div,
.cancel-round-dialog-buttons > div {
  min-width: 192px;
}

.close-or-open-round-dialog-icon-alert,
.cancel-round-dialog-icon-alert {
  color: #ff9601;
  width: 62px;
  height: 54px;
  margin-bottom: 8px;
}

.close-or-open-round-dialog-icon-close,
.cancel-round-dialog-icon-close {
  width: 14px;
  height: 14px;
  color: #afafaf;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.round-details-content-type-opportunity-box {
  display: flex;
  align-items: center;
  gap: 40px;
}

.round-details-content-list-item-term-language {
  grid-column: 1 / -1;
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.round-allocations-card-info-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.round-allocations-card-info {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 8px;
  width: 321px;
  height: 116px;
  background: var(--white);
  border: 1px solid #e9e9e9;
  border-radius: 12px;
}

.round-allocations-card-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-allocations-card-value {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.round-allocations-sectors {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 24px;
}
.round-allocations-sector-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.round-allocations-last-update {
  margin-top: 20px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-allocations-card {
  padding: 20px;
  background: var(--white);
  box-shadow: 0px 2px 2px rgba(30, 29, 29, 0.2);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.round-details-allocations-header-details span.space {
  font-size: 23px;
  margin-right: 12px;
  margin-left: 12px;
}

.vehicle-nationality {
  font-size: 12px;
  font-family: Source Sans Pro;
  font-weight: 700;
  color: #1e429f;
  border-radius: 6px;
  background-color: #e1effe;
  padding: 0px 10px;
  margin-left: 8px;
  display: inline-block;
  bottom: 4px;
  position: relative;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
</style>
